import React from 'react'
import UnitsForm, { UnitFormValues, ResidentInfo } from '../UnitsForm'
import { withRouter, RouteComponentProps } from 'react-router'
import { createUnit } from '../../../../services/Units'
import { createAndAssignRegistrant } from '../../../../services/Registrants'

interface State {
    isSaving: boolean
    error: string | null
}
interface Props extends RouteComponentProps {
    onSubmitClick?: () => void
    onCancelClick?: () => void
}

class UnitsCreate extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            isSaving: false,
            error: null,
        }
    }


    goBack() {
        if(this.props.onCancelClick) {
            this.props.onCancelClick()
        } else {
            this.props.history.goBack()
        }
        
    }

    async handleSave(data: UnitFormValues, residentInfo?: ResidentInfo) {
        this.setState({ isSaving: true, error: null })
        try {
            let residentData;
            if (residentInfo && residentInfo.FirstName && residentInfo.LastName) {
                residentData = {
                    FirstName: residentInfo.FirstName || "",
                    LastName: residentInfo.LastName || ""
                };
            } else {
                residentData = {
                    FirstName: 'Apartment',
                    LastName: data.Name
                };
            }
            if ('ExternalRequestIntegrationDeviceId' in data) {
                if (data.ExternalRequestIntegrationDeviceId === "") {
                    delete data.ExternalRequestIntegrationDeviceId;
                }
            }
            if ('intrexRoomId' in data) {
                if (data.intrexRoomId === "") {
                    delete data.intrexRoomId;
                }
            }
            const room = await createUnit(data);
            //auto create a resident for the room
            await createAndAssignRegistrant(
                residentData,
                room._id
            );
            this.setState({ isSaving: false })
            this.props.onSubmitClick && this.props.onSubmitClick()
        } catch (e) {
            this.setState({ isSaving: false, error: e.message })
        }
    }

    render() {
        return (
            <UnitsForm
                cancel={this.goBack.bind(this)}
                title="Create Room"
                unit={null}
                onSave={this.handleSave.bind(this)}
                {...this.state}
                isCreation={true}
            />
        )
    }
}

export default withRouter(UnitsCreate)
