import React, { useState, useEffect } from 'react'
import { Button, Message, Dimmer, Progress } from 'semantic-ui-react'
import { Promise } from 'bluebird'
import { getEndpoints, assertEndpointAddress, assertEndpointTimezone, customAPIThrottler } from '../../../../services/A4hEndpoints'
import { fetchAllUnits } from '../../../../services/Units'
import { fetchOneFacility, updateFacility } from '../../../../services/Facilities'
import { Facility } from '../../../../types/facilities'
import { AuthState } from '../../../../types'
import { useSelector } from 'react-redux'

const AssertAddress = props => {
    const profile = useSelector(({ authReducer }: { authReducer: AuthState }) => {
        return authReducer.profile
    })
    const [facility, setFacility] = useState<Facility | undefined>(undefined)
    const [fetchingFacility, setFetchingFacility] = useState<boolean>(true)
    const [missingParams, setMissingParams] = useState<boolean | undefined>(undefined)
    const [errMsg, setErrMsg] = useState<string>('')
    const [tzErrMsg, setTzErrMsg] = useState<string>('')
    const [successMsg, setSuccessMsg] = useState<string>('')
    const [tzSuccessMsg, setTzSuccessMsg] = useState<string>('')
    const [tz, setTz] = useState<string>('')
    const [fetchEndpointsProgress, setFetchEndpointsProgress] = useState<number>(0)
    const [assertAddressProgress, setAssertAddressProgress] = useState<number>(0)
    const [assertTimezoneProgress, setAssertTimezoneProgress] = useState<number>(0)
    const [currentStatusItem, setCurrentStatusItem] = useState<string>('')

    const processFacility = (fac) => {
        const {
            Address = '',
            City = '',
            State = '',
            ZIP = '',
            Name = '',
            FacilityTimeZone: Timezone = ''
        } = fac
        setFetchingFacility(false)
        if (!fac) {
            setMissingParams(true)
            return
        }
        setFacility(fac)
        if (!Address || !City || !State || !ZIP || !Name)
            setMissingParams(true)

        if (Timezone)
            setTz(Timezone)
    }

    const handleFacility = async (id = '') => {
        if (profile && profile.Facility) {
            setFetchingFacility(true)
            setMissingParams(false)
            const fac = await fetchOneFacility(profile.Facility)
            processFacility(fac)
        }
    }

    useEffect(() => {
        handleFacility(profile ? profile.Facility : "")
    }, [profile && profile.Facility])

    const handleAddrAssertion = async () => {
        setCurrentStatusItem('Step (1/3) Fetching Alexa devices')
        if (!(profile && profile.Facility)) {
            setErrMsg('Missing facility id')
            return
        }

        const dbRooms = await fetchAllUnits(profile && profile.Facility)
        const a4hRoomIds = dbRooms.map(r => r.a4hRoomId).filter(id => id)
        const endpointsRes = await Promise.map(
            a4hRoomIds,
            async (id, index) => {
                if (index > 10) {
                    setFetchEndpointsProgress(Math.round(100 - (index / a4hRoomIds.length) * 100))
                }
                let res
                do {
                    await Promise.delay(1000).then(async () => {
                        res = await getEndpoints(id)
                    })
                    const errors = [400, "Something went wrong"]
                    if (errors.includes(res.error))
                        res = []
                } while (res.error === 429) //too many requests
                return res
            },
            {
                concurrency: 9
            }
        )
        const endpointIds = endpointsRes.flat().filter(id => id)
        setCurrentStatusItem('Step (2/3) Asserting address to Alexa devices');
        setFetchEndpointsProgress(0);

        if (endpointIds) {
            const address = facility && facility.Address
            const city = facility && facility.City
            const state = facility && facility.State
            const postalCode = facility && facility.ZIP

            if (!address || !city || !state || !postalCode) {
                setErrMsg('Missing assertion params')
                return
            }

            const assertAddrResponse = await Promise.map(
                endpointIds,
                async (endpointId: string, index: number) => {
                    if (index > 10) {
                        setAssertAddressProgress(Math.round(100 - (index / endpointIds.length) * 100))
                    }
                    const resp = await customAPIThrottler(assertEndpointAddress, {
                        endpointId,
                        address,
                        city,
                        state,
                        postalCode
                    })
                    return resp;
                },
                {
                    concurrency: 9
                }
            )
            setCurrentStatusItem('Step (3/3) Asserting timezone to Alexa devices');
            setAssertAddressProgress(0);
            const failedResponseStatuses = assertAddrResponse.map(resp => (!resp.success && resp.status)).filter(status => status && status !== 201)
            console.log('failedResponseStatuses', failedResponseStatuses)
            if (failedResponseStatuses.includes(400))
                setErrMsg(`Assertion failed for ${failedResponseStatuses.length} devices, something went wrong please try again later`)
            else if (failedResponseStatuses.includes(401) || failedResponseStatuses.includes(403) || failedResponseStatuses.includes(404))
                setErrMsg(`Assertion failed for ${failedResponseStatuses.length} devices, please check if all the devices are online and connected to the internet`)
            else if (failedResponseStatuses.includes(429))
                setErrMsg('Amazon servers are overloaded at the moment please try again later')
            else if (failedResponseStatuses.includes(500) || failedResponseStatuses.includes(503))
                setErrMsg('Amazon servers are down at the moment please try again later')
            else
                setSuccessMsg('Successfully asserted addresses to supported and reachable alexa devices!')

            // assertEndpointTimezone
            if (tz) {
                console.log('asserting timezone', tz)
                const assertEndpointTimezoneResp = await Promise.map(
                    endpointIds,
                    async (id: string, index: number) => {
                        if (index > 10) {
                            setAssertTimezoneProgress(Math.round(100 - (index / endpointIds.length) * 100));
                        }
                        const resp = await customAPIThrottler(assertEndpointTimezone, {
                            endpointId: id,
                            timezoneName: tz
                        });
                        return resp;
                    },
                    {
                        concurrency: 9
                    }
                )
                setCurrentStatusItem('');
                setAssertTimezoneProgress(0);
                const failedAssertEndpointTimezoneStatuses = assertEndpointTimezoneResp.map(resp => (!resp.success && resp.status)).filter(status => status && status !== 204)
                console.log('failedAssertEndpointTimezoneStatuses', failedAssertEndpointTimezoneStatuses)
                if (failedAssertEndpointTimezoneStatuses.includes(400))
                    setTzErrMsg(`Timezone assertion failed for ${failedAssertEndpointTimezoneStatuses.length} devices, something went wrong please try again later`)
                else if (failedAssertEndpointTimezoneStatuses.includes(401) || failedAssertEndpointTimezoneStatuses.includes(403) || failedAssertEndpointTimezoneStatuses.includes(404) || failedAssertEndpointTimezoneStatuses.includes(405))
                    setTzErrMsg(`Timezone assertion failed for ${failedAssertEndpointTimezoneStatuses.length} devices, please check if all the devices are online and connected to the internet`)
                else if (failedAssertEndpointTimezoneStatuses.includes(429))
                    setTzErrMsg('Amazon servers are overloaded at the moment please try again later')
                else if (failedAssertEndpointTimezoneStatuses.includes(500) || failedAssertEndpointTimezoneStatuses.includes(503))
                    setTzErrMsg('Amazon servers are down at the moment please try again later')
                else
                    setTzSuccessMsg('Successfully asserted timezone to supported and reachable alexa devices!')
            }
        }
    }

    return (
        <>
            {profile && profile.Facility && !fetchingFacility &&
                <div>
                    <Dimmer inverted active={currentStatusItem !== ''}>
                        <div style={{color:'black'}}>
                            <strong>{`${currentStatusItem}`}</strong>
                            <Progress percent={currentStatusItem === 'Step (1/3) Fetching Alexa devices' ? fetchEndpointsProgress : currentStatusItem === 'Step (2/3) Asserting address to Alexa devices' ? assertAddressProgress : currentStatusItem === 'Step (3/3) Asserting timezone to Alexa devices' ? assertTimezoneProgress : 1} progress active color='blue'/>
                            <Message warning>This might take a while based on the number of Alexa devices in the facility</Message>
                        </div>
                    </Dimmer>
                    {facility && facility.Name ?
                        <div>
                            <h5>{facility.Name}</h5>
                            <ul>
                                <li>Address - {facility.Address || 'N/A'}</li>
                                <li>City - {facility.City || 'N/A'}</li>
                                <li>State - {facility.State || 'N/A'}</li>
                                <li>ZIP - {facility.ZIP || 'N/A'}</li>
                                <li>Country - US</li>
                                <li>Timezone - {tz || 'N/A'}</li>
                            </ul>
                            {missingParams && <p>
                                Please update your address to enable assertions
                            </p>}
                        </div>
                        :
                        <h4>Facility not found!</h4>
                    }

                    <div style={{ display: "flex", gap: "1px" }}>
                        <Button
                            type='button'
                            basic
                            content='Assert address'
                            onClick={handleAddrAssertion}
                            disabled={missingParams || !facility || !facility.Name}
                            color='blue'
                        />
                    </div>
                    {errMsg &&
                        <Message negative>{errMsg}</Message>
                    }
                    {successMsg &&
                        <Message positive>{successMsg}</Message>

                    }
                    {tzErrMsg &&
                        <Message negative>{tzErrMsg}</Message>
                    }
                    {tzSuccessMsg &&
                        <Message positive>{tzSuccessMsg}</Message>
                    }
                </div>
            }
        </>
    )
}

export default AssertAddress
